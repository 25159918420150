






import Vue from 'vue'
import CategoriesTree from '@/views/components/catalog/categories/CategoriesTree.vue'
import CategoriesMappingStore from '@/store/catalog/category/categoriesMapping'

export default Vue.extend({
  name: 'CatalogInstanceCategoriesVue',
  components: {
    CategoriesTree,
  },
  mounted() {
    CategoriesMappingStore.dispatch('getCategoriesMappingsForCatalogId', this.$route.params.catalogId)
  },
})
