import Vuex from 'vuex'
import Vue from 'vue'
import CategoryApiRequest from '@/store/catalog/category/categoryApiRequest'
import { AxiosResponse } from 'axios'
import Notifications from '@/store/notifications/notifications'

export interface CategoryMapping {
  id: number,
  name: string
}

export interface CategoryTreeElement {
  id: number,
  name: string,
  mappedCategory: CategoryMapping[],
  subtree: CategoryTreeElement[] | null
}

Vue.use(Vuex)
const getInitialState = (): any => ({
  dataLoading: true,
  mappings: [] as CategoryMapping[],
  refreshInfo: null,
  disableRefreshButton: false,
  buttonVariant: 'outline-primary',
})
export default new Vuex.Store({
  state: getInitialState(),
  mutations: {
    storeMappings(state: any, data: any) {
      state.mappings = data
      state.dataLoading = false
    },
    setDataLoading(state: any, isLoading: boolean) {
      state.dataLoading = isLoading
    },
    getFlatMappings(state: any) {
      return state.mappings
    },
    setRefreshInfo(state: any, data: any) {
      state.disableRefreshButton = true
      state.buttonVariant = 'outline-dark'
      state.refreshInfo = data
    },
    catalogChanged(state: any) {
      state.dataLoading = true
      state.mappings = [] as CategoryMapping[]
      state.refreshInfo = null
      state.disableRefreshButton = false
      state.buttonVariant = 'outline-primary'
    },
  },
  actions: {
    getCategoriesMappingsForCatalogId(context: any, catalogId: string) {
      context.commit('setDataLoading', true)
      CategoryApiRequest.getCategoryTreeMappings(catalogId)
        .then((result: AxiosResponse<any>) => {
          if (result.status !== 200) {
            Notifications.commit('addError', 'search.getCategoriesMappingsForCatalogId', { root: true })
            return
          }
          context.commit('storeMappings', result.data)
        })
        .catch(error => {
          Notifications.commit('addError', error, { root: true })
        })
    },
    updateCategoryByIdMapping(context: any, data: { categoryId: number, mappings: string[] }): void {
      CategoryApiRequest.updateCategoryByIdMapping(data.categoryId, data.mappings)
    },
    refreshCatalogCategories(context: any, catalogId: string): void {
      console.dir('refreshCatalogCategories')
      console.dir(catalogId)
      CategoryApiRequest.refreshCatalogCategories(catalogId)
        .then(result => {
          context.dispatch('getCategoriesMappingsForCatalogId', catalogId)
          context.commit('setRefreshInfo', result.data)
          return true
        })
        .catch(error => {
          Notifications.commit('addError', error, { root: true })
        })
    },
  },
})
