






















import CategoriesMappingStore, { CategoryTreeElement } from '@/store/catalog/category/categoriesMapping'
import CategoryTreeLevel from '@/views/components/catalog/categories/CategoryTreeLevel.vue'
import Vue from 'vue'

export default Vue.extend({
  name: 'CategoriesTree',
  components: { CategoryTreeLevel },
  computed: {
    disableButton() {
      return CategoriesMappingStore.state.disableRefreshButton
    },
    buttonVariant() {
      return CategoriesMappingStore.state.buttonVariant
    },
    refreshMessage() {
      if (CategoriesMappingStore.state.refreshInfo == null) {
        return null
      }
      const refreshInfo = CategoriesMappingStore.state.refreshInfo.data

      return this.$t('catalog.instance.categoriesTree.refreshInfo', {
        added: refreshInfo.categoriesAdded,
        total: refreshInfo.categoriesTotal,
      })
    },
    categories(): CategoryTreeElement {
      console.log(CategoriesMappingStore.state.mappings)
      return CategoriesMappingStore.state.mappings
    },

    // getFlatCategoriesMappings(): CategoryItemObject[] {
    //   const result: CategoryItemObject[] = []
    //
    //   if (CategoriesMappingStore.getters.dataLoading) {
    //     return []
    //   }
    //
    //   const traverse = (items: any[], depth: number) => {
    //     if (!items) {
    //       return
    //     }
    //
    //     items.forEach(item => {
    //       const categoryItem = new CategoryItemObject(
    //         item.id,
    //         item.name,
    //         item.mappedCategory,
    //         depth,
    //       )
    //       result.push(categoryItem)
    //
    //       if (item.subtree && item.subtree.length > 0) {
    //         traverse(item.subtree, depth + 1)
    //       }
    //     })
    //   }
    //
    //   traverse(CategoriesMappingStore.state.mappings, 0)
    //
    //
    //   console.dir(CategoriesMappingStore.state.mappings)
    //   console.dir(result)
    //   return result
    // },
  },
  mounted() {
    CategoriesMappingStore.commit('catalogChanged')
  },
  methods: {
    refreshCatalogCategories() {
      CategoriesMappingStore.dispatch('refreshCatalogCategories', this.$route.params.catalogId, { root: true })
    },
  },
})
