






























import Vue, { PropType } from 'vue'
import CategoriesMappingStore, { CategoryMapping, CategoryTreeElement } from '@/store/catalog/category/categoriesMapping'

export default Vue.extend({
  name: 'MappingCategoryComboBox',
  props: {
    category: {
      type: Object as PropType<CategoryTreeElement>,
      required: true,
    },
    mappings: {
      type: Array as PropType<CategoryMapping[]>,
      required: true,
    },
  },
  data() {
    return {
      checkedNames: [] as string[],
      availableMappings: [
        { id: 1, value: 'shoe', label: 'but' },
        { id: 2, value: 'glasses', label: 'okulary' },
        { id: 3, value: 'pants', label: 'spodnie' },
        { id: 4, value: 'miniskirt', label: 'minispódniczka' },
        { id: 5, value: 'dress', label: 'sukienka' },
        { id: 6, value: 'glove', label: 'rękawica' },
        { id: 7, value: 'wallet', label: 'portfel' },
        { id: 8, value: 'outerwear', label: 'odzież wierzchnia' },
        { id: 9, value: 'handbag', label: 'torebka' },
        { id: 10, value: 'skirt', label: 'spódnica' },
        { id: 11, value: 'shorts', label: 'szorty' },
        { id: 12, value: 'necklace', label: 'naszyjnik' },
        { id: 13, value: 'backpack', label: 'plecak' },
        { id: 14, value: 'overall', label: 'komplet' },
        { id: 15, value: 'belt', label: 'pas' },
        { id: 16, value: 'bag', label: 'torba' },
        { id: 17, value: 'sunglasses', label: 'okulary przeciwsłoneczne' },
        { id: 18, value: 'luggage', label: 'bagaż' },
        { id: 19, value: 'hat', label: 'kapelusz' },
        { id: 20, value: 'watch', label: 'zegarek' },
        { id: 21, value: 'bracelet', label: 'bransoletka' },
        { id: 22, value: 'scarf', label: 'szalik' },
        { id: 23, value: 'top', label: 'top' },
        { id: 24, value: 'earrings', label: 'kolczyki' },
        { id: 25, value: '_omit_', label: 'pomiń' },

      ],
      availableShoeMappings: [
        { id: 101, value: 'high_heels', label: 'obcasy' },
        { id: 102, value: 'sports_shoes,sneakers', label: 'obuwie sportowe' },
        { id: 103, value: 'knee_high_boots', label: 'kozaki za kolano' },
        { id: 104, value: 'flip_flops', label: 'klapki' },
        { id: 105, value: 'combat_boots', label: 'buty bojowe' },
        { id: 106, value: 'ballet_flats', label: 'baletki' },
        { id: 107, value: 'winter_boots', label: 'buty zimowe' },
        { id: 108, value: 'loafers,dress_shoes', label: 'mokasyny, półbuty' },
        { id: 109, value: 'sandals', label: 'sandały' },
      ],
    }
  },
  mounted() {
    const selectedMappings = this.mappings.map(m => m.name)

    this.availableMappings.forEach(availableMapping => {
      if (selectedMappings.includes(availableMapping.value)) {
        this.checkedNames.push(availableMapping.value)
      }
    })
    this.availableShoeMappings.forEach(availableShoeMapping => {
      if (selectedMappings.includes(availableShoeMapping.value)) {
        this.checkedNames.push(availableShoeMapping.value)
      }
    })
  },
  methods: {
    changed() {
      CategoriesMappingStore.dispatch('updateCategoryByIdMapping', {
        categoryId: this.category.id,
        mappings: this.checkedNames,
      })
    },
  },
})
