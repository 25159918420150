import { AxiosResponse } from 'axios'
import ApiRequestPaths from '@/apiRequestPaths'
import apiRequest from '@/apiRequest'

export default class CategoryApiRequest {
  static async getCategoryTreeMappings(catalogId: string): Promise<AxiosResponse<any>> {
    const catalogMappingUrl = ApiRequestPaths.GET_CATEGORY_MAPPING.replace('{catalogId}', catalogId)
    return apiRequest.get<any>(catalogMappingUrl)
  }

  static async updateCategoryByIdMapping(categoryId: number, mappings: string[]): Promise<AxiosResponse<any>> {

    return apiRequest.post<any>(ApiRequestPaths.UPDATE_MAPPINGS_FOR_CATEGORY_ID, {
      categoryId,
      mappings,
    })
  }

  static async refreshCatalogCategories(catalogId: string): Promise<AxiosResponse<any>> {
    const catalogRefreshCategoriesTree = ApiRequestPaths.REFRESH_CATALOG_CATEGORIES_TREE.replace('{catalogId}', catalogId)
    return apiRequest.get<any>(catalogRefreshCategoriesTree)
  }
}
