


































































import { CategoryMapping, CategoryTreeElement } from '@/store/catalog/category/categoriesMapping'
import Vue, { PropType } from 'vue'
import MappingCategoryComboBox from '@/views/components/catalog/categories/MappingCategoryComboBox.vue'

export default Vue.extend({
  name: 'CategoryTreeLevel',
  components: { MappingCategoryComboBox },
  props: {
    categories: {
      type: Array as PropType<CategoryTreeElement[]>,
      required: true,
    },
    parentMappedCategories: {
      type: Array as PropType<CategoryMapping[]>,
      required: false,
      default() {
        return []
      },
    },
    level: {
      type: Number,
      required: true,
    },
  },
  methods: {
    nextLevel(level: number): number {
      return Number(level) + 1
    },
    getSubTreeFromCategory(category: CategoryTreeElement): CategoryTreeElement[] {
      console.log(category)
      if (!category.subtree) {
        return []
      }

      return category.subtree
    },
    generateMappedCategories(category: CategoryTreeElement): CategoryMapping[] {
      if (category.mappedCategory.length === 0) {
        if (this.parentMappedCategories.length === 0) {
          return []
        }
        return this.parentMappedCategories
      }
      return category.mappedCategory
    },
    whatMappingHas(category: CategoryTreeElement): string {
      if (category.mappedCategory.length > 0) {
        return 'own'
      }
      if (this.parentMappedCategories.length > 0) {
        return 'parent'
      }
      return 'none'
    },
  },
})
